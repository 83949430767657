@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  @apply bg-gray-900 text-white;
  background-image: url('/public//background.png');
  background-size: cover; /* 确保背景图像覆盖整个区域 */
  background-repeat: no-repeat; /* 禁止背景图像重复 */
  background-position: center; /* 背景图像居中对齐 */
  background-attachment: fixed; /* 使背景图像固定在页面，不随着滚动移动 */
}

button {
  @apply bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 transition duration-300 ease-in-out;
}
